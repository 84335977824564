import { memo } from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import dayjs from "dayjs";
import { getChartParams, useInstrumentsChartApi } from "@utils/instrumentsApi";
import styles from "./SmallChart.module.css";

function LinearGradientFill({ color, instrumentId }) {
  return (
    <linearGradient id={instrumentId} x1="0%" y1="40%" x2="0%" y2="100%">
      <stop offset="10%" stopColor={color} stopOpacity="1" />
      <stop offset="100%" stopColor={color} stopOpacity="0" />
    </linearGradient>
  );
}

const SmallChart = ({ color, instrumentId, detailed = false, latestInstrumentPrice }) => {
  const { transformedData: chartData, isLoading } = useInstrumentsChartApi(
    {
      symbol: instrumentId,
      resolution: "30d",
      ...getChartParams("30d"), 
    },
    {
      refreshInterval: 30,
    },
    latestInstrumentPrice
  );

  if (!chartData && isLoading) return null;

  const simpleChart = chartData?.map((item) => item[1]);

  return (
    <div className={`${styles.SmallChart} h-full w-full`}>
      <Sparklines data={simpleChart || []}>
        <svg>
          <defs>
            <LinearGradientFill color={color} instrumentId={instrumentId} />
          </defs>
        </svg>
        <SparklinesLine
          style={{
            strokeWidth: 1,
            fill: `url(#${instrumentId})`,
          }}
          color="#000"
        />
      </Sparklines>
    </div>
  );
};

export default SmallChart;

export const MemoSmallChart = memo(SmallChart, function (prevProps, nextProps) {
  return prevProps.color === nextProps.color;
});
