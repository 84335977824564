/* eslint-disable lodash/collection-ordering */
import { useRef, useMemo } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import toNumber from "lodash/toNumber";
import qs from "qs";
import dayjs from "dayjs";

const fetcher = (...args) => fetch(...args).then((res) => res.json());
/**
 * [default description]
 *
 * @param   {[type]}  query    [sends your instruments (api_id) array as query string to fetch data from apiV2]
 * @param   {[type]}  refresh  [data refresh rate, in ms]
 * @param   {[type]}  10       [default refresh rate, 10ms]
 *
 * @return  {Object}           [return instrument object]
 */
export default function instrumentsApi(query, refresh = "10") {
  const currentDataRef = useRef(null);
  const previousDataRef = useRef(null);
  const { data: rawInstrumentData } = useSWR(
    `https://market-api.capex.com/marketdata?symbols=${query}&eventType=Quote`,
    fetcher,
    {
      refreshWhenOffline: false,
      refreshInterval: refresh,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
    }
  );

  // Transform the new API response format to match the expected structure
  const instrumentData = rawInstrumentData?.events
    ? rawInstrumentData.events.reduce((acc, event) => {
        if (event.type === "Quote" && event.symbol) {
          acc[event.symbol] = {
            buy: event.ask,
            sell: event.bid,
            price: (event.bid + event.ask) / 2, // Calculate mid price
            time: event.time,
          };
        }
        return acc;
      }, {})
    : {};

  previousDataRef.current = currentDataRef.current || undefined;
  currentDataRef.current = instrumentData;

  return { currentData: instrumentData, previousData: previousDataRef.current || currentDataRef.current };
}

/**
 * [default description]
 *
 * @param   {[type]}  query    [sends your instruments (api_id) array as query string to fetch data from apiV2]
 * @param   {[type]}  refresh  [data refresh rate, in ms]
 * @param   {[type]}  10       [default refresh rate, 10ms]
 *
 * @return  {Object}           [return instrument object]
 */
export function useInstrumentsApiRefresh(query, refresh = "10") {
  const currentDataRef = useRef(undefined);
  const previousDataRef = useRef(undefined);
  const { data: rawInstrumentData } = useSWR(
    `https://market-api.capex.com/marketdata?symbols=${query}&eventType=Quote`,
    fetcher,
    {
      refreshWhenOffline: false,
      refreshInterval: refresh,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      keepPreviousData: false,
    }
  );

  // Transform the new API response format to match the expected structure
  const instrumentData = rawInstrumentData?.events
    ? rawInstrumentData.events.reduce((acc, event) => {
        if (event.type === "Quote" && event.symbol) {
          acc[event.symbol] = {
            buy: event.ask,
            sell: event.bid,
            price: (event.bid + event.ask) / 2, // Calculate mid price
            time: event.time,
          };
        }
        return acc;
      }, {})
    : {};

  // Safe assignment of current and previous data references
  previousDataRef.current = currentDataRef.current || {};
  currentDataRef.current = instrumentData || {};

  if (previousDataRef?.current && currentDataRef?.current) {
    try {
      Object.keys(currentDataRef.current).forEach((api_id) => {
        if (api_id && previousDataRef.current[api_id] && currentDataRef.current[api_id]) {
          if (previousDataRef.current[api_id]?.buy !== currentDataRef.current[api_id]?.buy) {
            currentDataRef.current[api_id].isActive = true;
            previousDataRef.current[api_id].cancelActive = 0;
          } else if (previousDataRef.current[api_id]?.sell !== currentDataRef.current[api_id]?.sell) {
            currentDataRef.current[api_id].isActive = true;
            previousDataRef.current[api_id].cancelActive = 0;
          } else if (previousDataRef.current[api_id]?.price !== currentDataRef.current[api_id]?.price) {
            currentDataRef.current[api_id].isActive = true;
            previousDataRef.current[api_id].cancelActive = 0;
          } else {
            const previousCount = Number.parseInt(previousDataRef.current[api_id]?.cancelActive, 10) || 0;
            currentDataRef.current[api_id].cancelActive = previousCount + 1;
            if (previousDataRef.current[api_id]?.cancelActive > 15) {
              currentDataRef.current[api_id].isActive = false;
            }
          }
        }
      });
    } catch (err) {
      console.warn(err);
    }
  }

  return { currentData: instrumentData, previousData: previousDataRef?.current || currentDataRef?.current };
}

export function gainersAndLosers(query, refresh = "0") {
  const { data } = useSWRImmutable(`https://api-v2.capex.com/quotesv2?key=1`, fetcher);
  const dataWithoutPercentage = values(data)
    .filter((item) => {
      if (item.change !== "-") {
        return item;
      }
    })
    .map((item) => ({
      ...item,
      ...(item.change && { numberChange: toNumber(item.change.replace("%", "")) }),
    }));
  const gainers = orderBy(dataWithoutPercentage, ["numberChange"], ["desc"]).slice(0, 50);
  const losers = orderBy(dataWithoutPercentage, ["numberChange"], ["asc"]).slice(0, 50);

  return { gainers, losers };
}

// New exported function for chart parameters
export function getChartParams(resolution) {
  switch (resolution) {
    case "7d":
      return {
        fromDate: dayjs().subtract(10, "day").format("YYYY-MM-DD"), // 10 days to account for weekends
        requiredItems: 7,
        candleType: "d", // Default to daily candles
      };
    case "30d":
      return {
        fromDate: dayjs().subtract(42, "day").format("YYYY-MM-DD"), // ~6 weeks to account for weekends
        requiredItems: 30,
        candleType: "d", // Default to daily candles
      };
    case "6m":
      return {
        fromDate: dayjs().subtract(8, "month").format("YYYY-MM-DD"), // Extra time for holidays/missing data
        requiredItems: 26, // ~6 months in weeks
        candleType: "w", // Weekly candles
      };
    case "1y":
      return {
        fromDate: dayjs().subtract(15, "month").format("YYYY-MM-DD"), // Extra time for holidays/missing data
        requiredItems: 52, // ~1 year in weeks
        candleType: "w", // Weekly candles
      };
    case "5y":
      return {
        fromDate: dayjs().subtract(5, "years").format("YYYY-MM-DD"), // Extra time for holidays/missing data
        requiredItems: null, // No limit to required items
        candleType: "mo", // Monthly candles
      };
    default:
      return {
        fromDate: dayjs().subtract(10, "day").format("YYYY-MM-DD"),
        requiredItems: 7,
        candleType: "d", // Default to daily candles
      };
  }
}

/**
 * Transforms raw candle data into chart-friendly format
 *
 * @param {Object} data - Raw instrument candle data from API
 * @param {Object} currentQuoteData - Current quote data for the instrument
 * @param {number} requiredItems - Number of data points required for the chart
 * @returns {Array} - Formatted array of timestamp/price pairs for charting
 */
export function transformChartData(data, currentQuoteData, requiredItems) {
  // Return empty array if data is invalid
  if (!data || !Array.isArray(data.events)) return [];

  // Process and transform candle data
  let chartPoints = data.events
    // Filter out any candles with null values
    .filter((candle) => {
      return candle.open !== null && candle.high !== null && candle.low !== null && candle.close !== null;
    })
    // Transform to [timestamp, close_price] format
    .map((candle) => {
      const timestamp = dayjs(candle.time).valueOf();
      return [timestamp, candle.close];
    })
    // Ensure data is sorted chronologically
    .sort((a, b) => a[0] - b[0]);

  // Add current price point if available
  if (currentQuoteData && currentQuoteData.buy && currentQuoteData.time) {
    const currentTimestamp = dayjs(currentQuoteData.time).valueOf();
    const lastChartPoint = chartPoints.length > 0 ? chartPoints[chartPoints.length - 1] : null;

    // Determine if we should add or replace the last point
    let shouldAddCurrentPoint = true;

    if (lastChartPoint) {
      const lastPointDate = dayjs(lastChartPoint[0]).startOf("day");
      const currentPointDate = dayjs(currentTimestamp).startOf("day");

      // If we already have a point for today, replace it with the current price
      if (lastPointDate.isSame(currentPointDate)) {
        chartPoints[chartPoints.length - 1] = [currentTimestamp, currentQuoteData.buy];
        shouldAddCurrentPoint = false;
      }
    }

    // Add a new point if needed
    if (shouldAddCurrentPoint) {
      chartPoints.push([currentTimestamp, currentQuoteData.buy]);
    }
  }

  // Limit the number of data points to the required amount
  if (requiredItems && chartPoints.length > requiredItems) {
    chartPoints = chartPoints.slice(chartPoints.length - requiredItems);
  }

  return chartPoints;
}

/**
 * Fetches and processes instrument chart data
 *
 * @param {Object} query - Query parameters including symbol, resolution, fromDate, etc.
 * @param {Object} swrConfig - Configuration options for SWR
 * @param {Object} currentPriceData - Current quote data. Used to determine if we need to display it as last item in the chart (if market open), in case the market is closed, we'll basically display the last price from last candle of chart api
 * @returns {Object} - Object containing chart data, loading state, and metadata
 */
export function useInstrumentsChartApi(query, swrConfig, currentPriceData = null) {
  // Create stable query params object to prevent unnecessary re-renders
  const queryParams = useMemo(() => {
    // Start with base query
    const params = { ...query };
    let requiredItems = null;

    // If resolution is provided but not fromDate, calculate appropriate time range
    if (query.resolution && !query.fromDate) {
      const chartParams = getChartParams(query.resolution);

      // Extend params with calculated date range
      Object.assign(params, {
        fromDate: chartParams.fromDate,
        toDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      });

      requiredItems = chartParams.requiredItems;
    }

    return { params, requiredItems };
  }, [query]);

  // Generate query string for API call
  const qsQuery = useMemo(
    () =>
      qs.stringify(
        {
          symbols: queryParams.params.symbol,
          fromDate: queryParams.params.fromDate,
          toDate: queryParams.params.toDate || dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          eventType: "Candle",
          candleType: queryParams.params.candleType || "d", // Default to daily candles
        },
        {
          addQueryPrefix: true,
        }
      ),
    [queryParams.params]
  );

  // Extract current quote data from provided price data
  const currentQuoteData = useMemo(() => {
    if (currentPriceData && queryParams.params.symbol) {
      return currentPriceData || null;
    }
    return null;
  }, [currentPriceData, queryParams.params.symbol]);

  // Fetch chart data using SWR
  const {
    data: rawInstrumentData,
    isLoading,
    error,
  } = useSWR(`https://market-api.capex.com/marketdata${qsQuery}`, fetcher, {
    refreshWhenOffline: false,
    refreshInterval: 30000,
    revalidateOnReconnect: true,
    refreshWhenHidden: false,
    dedupingInterval: 20000,
    ...swrConfig,
  });

  // Transform raw data into chart-friendly format
  const transformedData = useMemo(() => {
    return transformChartData(rawInstrumentData, currentQuoteData, queryParams?.params?.requiredItems);
  }, [rawInstrumentData, currentQuoteData, queryParams?.params?.requiredItems]);
  // Return comprehensive result object
  return {
    currentData: rawInstrumentData, // Raw API response
    transformedData, // Processed chart data
    isLoading, // Loading state
    error, // Any error from API
    requiredItems: queryParams.requiredItems, // Number of data points required
    currentQuoteData, // Current quote data used
  };
}
